
import { Component, Prop } from 'vue-property-decorator';
import VFormBuilder from '../../shared/form/VFormBuilder.vue';
import { InputType } from '@/enums/InputType';
import { Venue } from '@/interfaces/models/Venue';
import StackedForm from '@/mixins/StackedForm';
import { venueFilter } from '@/util/helper';

@Component({
  components: { VFormBuilder },
})
export default class VenueSyncForm extends StackedForm {
  @Prop({ type: Array, required: true }) public venues!: Venue[];
  @Prop({ type: String, required: true }) public source!: string;

  get items() {
    return [
      { name: 'source', type: InputType.Text, label: 'venue.form.source', disabled: true, default: this.source },
      {
        name: 'venues',
        type: InputType.Autocomplete,
        multiple: true,
        label: 'venue.form.venues',
        itemValue: '_id',
        itemText: 'name',
        filter: venueFilter,
        items: this.venueItems,
        rules: 'required',
      },
      {
        name: 'type',
        type: InputType.Select,
        label: 'venue.form.syncType',
        items: this.syncTypes,
        rules: 'required',
        default: 'foodcard',
      },
      {
        name: 'properties',
        type: InputType.Select,
        label: 'venue.form.properties',
        items: this.properties,
        multiple: true,
        visible: this.visibleCallback,
      },
    ];
  }

  get syncTypes() {
    return ['foodcard', 'venue'];
  }

  get venueItems(): Venue[] {
    if (this.venues && this.venues.length > 0) {
      return this.venues.filter((v: Venue) => {
        if (!v.sync) {
          return true;
        }
        return v.sync.canSync;
      });
    }

    return [];
  }

  public visibleCallback(form: { type?: 'foodcard' | 'venue' }) {
    return !!(form.type && form.type === 'venue');
  }

  get properties() {
    return [
      'offsets',
      'movDelivery',
      'openingHours',
      'deliveryHours',
      'invoiceReceiver',
      'deliveryEnabled',
      'preorderTakeAway',
      'preorderIn',
      'sendProvisionReportPeriod',
      'isServiceActivated',
      'slot',
    ];
  }
}
