
import { Component, Vue } from 'vue-property-decorator';
import VWrapper from '@/components/shared/VWrapper.vue';
import { namespace } from 'vuex-class';
import { Venue } from '@/interfaces/models/Venue';
import VenueApiService from '@/api/http/VenueApiService';
import VenueSyncForm from '@/components/venue/form/VenueSyncForm.vue';
import { mixins } from 'vue-class-component';
import StackedForm from '@/mixins/StackedForm';

const app = namespace('app');
const venue = namespace('venue');

@Component({
  components: { VenueSyncForm, VWrapper },
})
export default class VenueSync extends mixins(StackedForm) {
  @app.State('venues') public items!: Venue[];
  @venue.State('active') public venue!: Venue;
  @venue.Action('show') public show!: any;

  public async mounted() {
    this.$startLoading('venue');
    await this.show({ id: this.$route.params.id });
    this.$stopLoading('venue');
  }

  public startSync() {
    this.validate().then((res: boolean[] | boolean) => {
      if (this.isValid(res)) {
        const api: VenueApiService = new VenueApiService();
        api.startSync({ venue: this.$route.params.id, ...this.$refs.form.getData() }).then(() => {
          this.$router.push({ name: 'venue.index' });
        });
      }
    });
  }
}
